import { createContext } from "react";
import useProducts from "../hooks/useProducts";

const initialValues: any = {
  products: [],
  rol: [],
};

const ProductsContext = createContext(initialValues);

function ProductsProvider(props: any) {
  const {
    products,
    totalProductsSize,
    categories,
    rol,
    editableProduct,
    limit,
    hasMoreData,
    loading,
    setEditableProduct,
    handleSetDBProducts,
    handleSetDBCategories,
    setProducts,
    handleSetDBRol
  } = useProducts();
  return (
    <ProductsContext.Provider
      value={{
        products,
        categories,
        editableProduct,
        rol,
        limit,
        hasMoreData,
        loading,
        totalProductsSize,
        setEditableProduct,
        handleSetDBProducts,
        handleSetDBCategories,
        handleSetDBRol,
        setProducts,
      }}
    >
      {props.children}
    </ProductsContext.Provider>
  );
}

export { ProductsProvider, ProductsContext };
