import { makeRequest } from "../../../common/request";
import { StateAccountProps } from "../../../services/canje/insertTransactionExchange";
import { URL_QUERY_OPERATIONS } from "../../../services/serviceurl";
import { updateStateAccountInDB } from "../../../services/state_account/updateStateAccountInDB";
import { convertMonthInNumber } from "../../../utils/getNumberMonth";

const CURRENT_MONTH = new Date().getMonth() + 1;
const CURRENT_YEAR = new Date().getFullYear();

function sortedStateAccount2(stateAccount: StateAccountProps[]) {
  return stateAccount.sort((a: StateAccountProps, b: StateAccountProps) => {
    // Asegurarse de que 'anio' se trata como número
    const anioA = Number(a.anio); // Convertir a número si es una cadena
    const anioB = Number(b.anio); // Convertir a número si es una cadena

    // Comparar primero los años (descendente)
    if (anioA !== anioB) {
      return anioB - anioA; // El año más grande (más reciente) va primero
    }

    // Comparar los meses dentro del mismo año (ascendente)
    const mesA = convertMonthInNumber(a.mes);
    const mesB = convertMonthInNumber(b.mes);
    return mesA - mesB; // Ordenar los meses en orden ascendente
  });
}

function calculateAccountData(
  currentStateAccount: StateAccountProps[]
): StateAccountProps[] {
  const stateAccount = currentStateAccount.map((item) => ({ ...item }));

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1;
  const currentYear = currentDate.getFullYear();

  for (let i = 0; i < stateAccount.length; i++) {
    const {
      bonus_panimillas = 0,
      sacos_panimillas = 0,
      millas_canjeadas = 0,
      mes,
      anio,
    } = stateAccount[i];

    const month = convertMonthInNumber(mes);

    if (month < 1 || month > 12) {
      //console.log(`Mes inválido (${mes}) en la posición ${i}`);
      continue;
    }

    const previousMiles =
      i > 0
        ? stateAccount[i - 1]?.millas_disponibles_canje || 0
        : stateAccount[i]?.millas_disponibles_canje || 0;

    const availableMiles = previousMiles + bonus_panimillas + sacos_panimillas;

    if (anio === currentYear) {
      if (month < currentMonth) {
        stateAccount[i].millas_disponibles_canje =
          availableMiles - millas_canjeadas;
      } else if (month === currentMonth) {
        stateAccount[i].millas_disponibles_canje =
          previousMiles + sacos_panimillas - millas_canjeadas;
      }
    }
  }

  return stateAccount;
}

const verifyStateAccountsByMonth = async (id_cliente: number) => {
  let auxiliarClientStateAccount: StateAccountProps[] = [];
  try {
    const response = await getStateAccountForClients(id_cliente);
    if (response && response.status === 200) {
      const stateAccountList = response?.data?.recordset || [];
      const sortedClientAccount = sortedStateAccount(stateAccountList);
      auxiliarClientStateAccount = calculateAccountData(
        Object.assign([], sortedClientAccount)
      );
      await verifyConsistData(sortedClientAccount, auxiliarClientStateAccount);
    }
    const currentStateAccount = auxiliarClientStateAccount.find(
      (stateAccount) =>
        stateAccount.anio === CURRENT_YEAR &&
        convertMonthInNumber(stateAccount.mes) === CURRENT_MONTH
    );

    const availableMiles =
      auxiliarClientStateAccount[auxiliarClientStateAccount.length - 1]
        .millas_disponibles_canje || 0;

    return {
      millas_disponibles_canje: availableMiles || 0,
      estado_cuenta: auxiliarClientStateAccount || [],
      registros_mes_actual: currentStateAccount || 0,
    };
  } catch (error) {
    // console.warn(
    //   "ERROR AL CONSULTAR EL ESTADO DE LAS CUENTAS DEL USUARIO: ",
    //   error
    // );
    return null;
  }
};

// const validateEmptyInAccounts = async (id_cliente: number) => {
//   let stateAccountList = [];
//   let sortedClientAccount = [];
//   let auxiliarClientStateAccount: StateAccountProps[] = [];
//   try {
//     const response = await getStateAccountForClients(id_cliente);
//     if (response && response.status === 200) {
//       stateAccountList = response?.data?.recordset || [];
//       sortedClientAccount = sortedStateAccount(stateAccountList);
//       const currentMonthRecords = stateAccountList.filter(
//         (account: StateAccountProps) =>
//           convertMonthInNumber(account.mes) === CURRENT_MONTH &&
//           account.anio === CURRENT_YEAR
//       );

//       console.warn("CUENTAS DEL USUARIO: ", sortedClientAccount);
//       let isEmpty = sortedClientAccount
//         .slice(0, stateAccountList.length - 1)
//         .some(
//           (item) =>
//             item?.millas_disponibles_canje == null ||
//             item?.millas_disponibles_canje === 0
//         );
//       console.warn("HAY CUENTAS VACIAS: ", isEmpty);
//       if (isEmpty) {
//         const secondResponse = await getStateAccountForClients(
//           id_cliente
//           //false
//         );

//         if (secondResponse && secondResponse.status === 200) {
//           stateAccountList = secondResponse?.data?.recordset || [];
//           sortedClientAccount = sortedStateAccount(stateAccountList);
//           auxiliarClientStateAccount = calculateAccountData(
//             Object.assign([], sortedClientAccount)
//           );
//           await verifyConsistData(
//             sortedClientAccount,
//             auxiliarClientStateAccount
//           );
//         }
//       } else {
//         auxiliarClientStateAccount = calculateCurrentMonthAccountData(
//           Object.assign([], sortedClientAccount)
//         );
//         await verifyConsistData(
//           sortedClientAccount,
//           auxiliarClientStateAccount
//         );
//       }
//       return {
//         millas_disponibles_canje:
//           auxiliarClientStateAccount[auxiliarClientStateAccount.length - 1]
//             .millas_disponibles_canje || 0,
//         estado_cuenta: auxiliarClientStateAccount,
//         registros_mes_actual:
//           currentMonthRecords && currentMonthRecords[0] ? true : false,
//       };
//     }
//   } catch (e) {}
// };

async function verifyConsistData(
  stateAccount: StateAccountProps[],
  auxiliarStateAccount: StateAccountProps[]
) {
  for (let i = 0; i < stateAccount.length; i++) {
    let availablePanimillas = stateAccount[i].millas_disponibles_canje;
    let availableAuxiliarPanimillas =
      auxiliarStateAccount[i].millas_disponibles_canje;

    if (availablePanimillas !== availableAuxiliarPanimillas) {
      try {
        await updateStateAccountInDB(
          availableAuxiliarPanimillas || 0,
          auxiliarStateAccount[i].id_estado_cuenta
        );
      } catch (e) {}
    }
  }
}

// function calculateCurrentMonthAccountData(
//   currentStateAccount: StateAccountProps[]
// ) {
//   let stateAccount = Object.assign(
//     [],
//     currentStateAccount.map((item) => {
//       return { ...item };
//     })
//   ) as StateAccountProps[];

//   for (let i = 0; i < stateAccount.length; i++) {
//     const { bonus_panimillas, sacos_panimillas, millas_canjeadas, mes, anio } =
//       stateAccount[i];
//     let month = convertMonthInNumber(mes);

//     if (anio < CURRENT_YEAR) {
//       stateAccount[i].millas_disponibles_canje =
//         (stateAccount[i - 1]?.millas_disponibles_canje || 0) +
//         (bonus_panimillas || 0) +
//         (sacos_panimillas || 0) -
//         (millas_canjeadas || 0);
//     } else if (anio === CURRENT_YEAR && month < CURRENT_MONTH) {
//       if (i > 0) {
//         stateAccount[i].millas_disponibles_canje =
//           (stateAccount[i - 1]?.millas_disponibles_canje || 0) +
//           (bonus_panimillas || 0) +
//           (sacos_panimillas || 0) -
//           (millas_canjeadas || 0);
//       } else {
//         stateAccount[i].millas_disponibles_canje =
//           (bonus_panimillas || 0) +
//           (sacos_panimillas || 0) -
//           (millas_canjeadas || 0);
//       }
//     } else if (anio === CURRENT_YEAR && month === CURRENT_MONTH) {
//       if (month < 1) {
//         stateAccount[i].millas_disponibles_canje =
//           (sacos_panimillas || 0) - (millas_canjeadas || 0);
//       } else {
//         stateAccount[i].millas_disponibles_canje =
//           (stateAccount[i - 1]?.millas_disponibles_canje || 0) +
//           (sacos_panimillas || 0) -
//           (millas_canjeadas || 0);
//       }
//     }
//   }

//   return stateAccount;
// }

export function sortedStateAccount(stateAccount: StateAccountProps[]) {
  const sortedAccount = Object.assign([], stateAccount);
  sortedAccount.sort((a: StateAccountProps, b: StateAccountProps) => {
    const monthComparison =
      convertMonthInNumber(a.mes) - convertMonthInNumber(b.mes);

    if (monthComparison !== 0 && a.anio === b.anio) {
      return monthComparison;
    } else {
      return a.anio - b.anio;
    }
  });

  //console.warn("ESO DEVUELVE SORTED: ", sortedAccount);

  return sortedAccount;
}

const getStateAccountForClients = async (
  id_cliente: number
  //withTop: boolean
) => {
  try {
    const response = await makeRequest("POST", URL_QUERY_OPERATIONS, null, {
      operation: "SA",
      id_cliente,
      //withTop,
    });
    //console.warn("CUENTAS QUE TE ESTOY TRAYENDO: ", response);
    //console.warn("with top: ", withTop);
    return response;
  } catch (e) {
    return null;
  }
};

export async function queryStateAccount(id_cliente: number) {
  try {
    let stateAccountData = await verifyStateAccountsByMonth(id_cliente);
    if (stateAccountData) {
      return stateAccountData;
      // return {
      //   millas_disponibles_canje:
      //     stateAccountData.millas_disponibles_canje || 0,
      //   estado_cuenta: stateAccountData.estado_cuenta,
      //   registros_mes_actual: stateAccountData.registros_mes_actual || false,
      // };
    } else {
      return {
        millas_disponibles_canje: 0,
        estado_cuenta: [],
        registros_mes_actual: false,
      };
    }
  } catch (e) {
    return {
      millas_disponibles_canje: 0,
      estado_cuenta: [],
    };
  }
}
