export type ISession = SessionMethods & IReducer;

export type Rol = { id_rol: number; nombre_rol: string };

export type SessionMethods = {
  sendCode: (agr0: string) => any;
  signIn: (agr0: boolean) => any;
  signOut: (arg0?: boolean) => any;
  setLoading: (arg0: boolean) => any;
  handleVerifyByID: (arg0: string) => any;
  handleSendOtp: (arg0: string) => any;
  handleValidOtp: (arg0: string, arg1: string) => any;
  handleLogin: () => any;
  handleAceptTYC: () => any;
};

export interface IReducer {
  products: Product[] | null | any;
  totalProductsSize: number | null;
  editableProduct: Product | null;
  categories: Category[] | null;
  rol: Rol[] | null;
  limit: number;
  hasMoreData: boolean;
  loading: boolean;
}

export type Product = {
  id_categoria: number;
  id_premio: number;
  nombre_premio: string;
  imagen_url: string;
  temporal_url?: string;
  codigo_sap: number;
  es_externo: number;
  descripcion: string;
  costo_unitario: number;
  estado: number;
  correo_responsable: string;
  au_id_usuario_creacion: string;
  au_fecha_modificacion: string;
  au_fecha_creacion: string;
  nombre_categoria?: string;
};

export type Category = {
  id_categoria: number;
  nombre_categoria: string;
};

export type Premio = {
  id_premio: number;
  id_categoria: number;
  nombre_premio: string;
  imagem_url: string;
  codigo_sap: string;
  es_externo: boolean;
  descripcion: string;
  costo_unitario: number;
  estado: boolean;
  correo_responsable: string;
};

export enum ActionTypes {
  SET_PRODUCTS = "SET PRODUCTS",
  SET_TOTAL_PRODUCTS_SIZE = "SET TOTAL PRODUCTS SIZE",
  SET_EDIT_PRODUCT = "SET EDIT PRODUCT",
  SET_CATEGORIES = "SET CATEGORIES",
  SET_ROL = "SET ROL",
  SET_LOADING = "SET LOADING",
  SET_HAS_MORE_DATA = "SET HAS MORE DATA",
  SET_LIMIT = "SET LIMIT",
}

export type ReducerAction =
  | { action: ActionTypes.SET_PRODUCTS; payload: Product[] }
  | { action: ActionTypes.SET_TOTAL_PRODUCTS_SIZE; payload: number | null }
  | {
      action: ActionTypes.SET_EDIT_PRODUCT;
      payload: Product;
    }
  | {
      action: ActionTypes.SET_CATEGORIES;
      payload: Category[];
    }
  | {
      action: ActionTypes.SET_LOADING;
      payload: boolean;
    }
  | {
      action: ActionTypes.SET_HAS_MORE_DATA;
      payload: boolean;
    }
  | {
      action: ActionTypes.SET_LIMIT;
      payload: number;
    }
  | {
      action: ActionTypes.SET_ROL;
      payload: Rol[];
    }
  | {
      action: ActionTypes.SET_LOADING;
      payload: boolean;
    }
  | {
      action: ActionTypes.SET_HAS_MORE_DATA;
      payload: boolean;
    }
  | {
      action: ActionTypes.SET_LIMIT;
      payload: number;
    };
