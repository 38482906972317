//PRODUCTION
export const URL_QUERY_OPERATIONS =
  "https://panimillas.azurewebsites.net/api/queryOperations?";
export const URL_GET_DATA =
  "https://panimillas.azurewebsites.net/api/getDataInfo?";
export const URL_TRANSACTION =
  "https://panimillas.azurewebsites.net/api/transactionQuery?";
export const URL_SEND_EMAIL =
  "https://panimillas.azurewebsites.net/api/sendEmails";
export const URL_QUERY_OTP =
  "https://panimillas.azurewebsites.net/api/queryOtp?";
export const URL_QUERY_TOKEN =
  "https://panimillas.azurewebsites.net/api/queryToken?";

//TEST
// export const URL_QUERY_OPERATIONS = 'https://panimillastest.azurewebsites.net/api/queryOperations?';
// export const URL_GET_DATA = 'https://panimillastest.azurewebsites.net/api/getDataInfo?';
// export const URL_TRANSACTION = 'https://panimillastest.azurewebsites.net/api/transactionQuery?';
// export const URL_SEND_EMAIL = 'https://panimillastest.azurewebsites.net/api/sendEmails';
// export const URL_QUERY_OTP = 'https://panimillastest.azurewebsites.net/api/queryOtp?';
// export const URL_QUERY_TOKEN = 'https://panimillastest.azurewebsites.net/api/queryToken?';
